import React, { useContext, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import MeetingsDataService from '../../services/meetings'
import { StyledH1V2, StyledH2V2 } from '../../styles/styledComponents'
import Loading from '../../components/loading'
import Pill from '../../components/shared/pills'
import participants from '../../assets/pills/participants.svg'
import calendar from '../../assets/pills/calendar.svg'
import custom_words from '../../assets/pills/custom_words.svg'
import clock from '../../assets/pills/clock.svg'
import send from '../../assets/pills/send.svg'
import edit from '../../assets/icons/edit.svg'
import chat from '../../assets/icons/chat.svg'
import moment from 'moment'
import { Button, Checkbox, Drawer, Form, Input } from 'antd'
import { toast } from 'react-toastify'
import CustomWordsContainer from '../customWordsContainer'
import Recipients from '../../components/recipients'
import { useForm } from 'antd/es/form/Form'
import TasksContainer from '../tasksContainer'
import TopicsContainer from '../topicsContainer'
import ChatContainer from '../chatContainer'
import TranscriptContainer from '../transcriptContainer'
import { RecordingButton } from './styledComponents'
import { PlayCircleOutlined } from '@ant-design/icons'
import UnlockFeature from '../../components/unlockFeature'
import { StyledPillContent } from '../../components/shared/pills/styledComponents'
import Generation from '../../components/templates/generation'

const MeetingDetialsContainer: React.FC = () => {
  const [sendNotesForm] = useForm()
  const [showModal, setShowModal] = useState(false)
  const [showModalChat, setShowModalChat] = useState(false)
  const [showModalCW, setShowModalCW] = useState(false)
  const { permissions } = useContext(JamyContext)
  const [recipientsForm] = useForm()
  const [summaryForm] = useForm()
  const [summaryEdit, setSummaryEdit] = useState(false)
  const [updateArg, setUpdateArg] = useState('')
  const [summaryArray, setSummaryArray] = useState<string[]>([])
  const [seeMoreText, setSeeMoreText] = useState('See more')
  const [closePopOver, setClosePopOver] = useState(false)
  const [loading, setLoading] = useState(true)
  const { isAuthenticated } = useContext(JamyContext)
  const [meetingItem, setMeetingItem] = useState<Meetings.Results>()
  const { meeting_id } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const meetingDataService = new MeetingsDataService()
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (isAuthenticated && meeting_id) {
      navigate(pathname)
      getMeetingDetail(meeting_id?.split('&')[0])
    } else {
      if (searchParams.get('email')) {
        meetingDataService
          .getMeetingBanner(searchParams.get('email'))
          .then((response) =>
            redirectUserWhenMailExist(
              response.data.type,
              response.data.invitation_url
            )
          )
          .catch((e) => console.error(e))
      }
    }
  }, [meeting_id, isAuthenticated])

  useEffect(() => {
    if (meetingItem) {
      recipientsForm.setFieldsValue({ last_name: '', first_name: '' })
      setSummaryArray(meetingItem?.summary.split('\n\n').slice(0, 2))
      summaryForm.setFieldValue('summary', meetingItem?.summary)
      sendNotesForm.setFieldValue('email', true)
      sendNotesForm.setFieldValue('slack', true)
    }
  }, [meetingItem])

  useEffect(() => {
    if (updateArg !== '' && meetingItem) {
      updateMeeting(meetingItem, updateArg)
    }
  }, [updateArg])

  const redirectUserWhenMailExist = (type: string, url?: string) => {
    switch (type) {
      case 'sign_up':
        navigate(
          `/register?redirect=/meetings/details/${meeting_id}&type=${type}&email=${searchParams.get(
            'email'
          )}`
        )
        break
      case 'log_in':
        navigate(
          `/login?redirect=/meetings/details/${meeting_id}&type=${type}&email=${searchParams.get(
            'email'
          )}`
        )
        break
      case 'join_org':
        navigate(
          url
            ? `${
                url?.split('app.jamy.ai')[1]
              }?type=join_org?redirect=/meetings/details/${meeting_id}&email=${searchParams.get(
                'email'
              )}&type=join_org`
            : ''
        )
        break
      default:
        break
    }
  }

  const getMeetingDetail = (meeting_id: string) => {
    const meetingService = new MeetingsDataService()
    meetingService
      .getMeetingById(meeting_id)
      .then((response) => {
        setMeetingItem(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const onClicSendNotes = (data: any) => {
    console.log(data)
    setClosePopOver(!closePopOver)
    const meetingService = new MeetingsDataService()
    meetingService
      .sendNotes(meetingItem ? meetingItem.id : '', data.slack, data.email)
      .then(() => {
        setLoading(false)
        sendNotesForm.setFieldValue('email', true)
        sendNotesForm.setFieldValue('slack', true)
        toast.success('Notes have been sent', { theme: 'colored' })
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Notes havent been sent, try again', { theme: 'colored' })
        console.log(error)
      })
  }

  const onClicEditRecipients = (value: string) => {
    recipientsForm.setFieldValue('email', value)
    if (meetingItem) {
      setMeetingItem({
        ...meetingItem,
        recipients: meetingItem.recipients.filter((x) => x.email !== value)
      })
    }
  }

  const onClicDeleteRecipients = (value: string) => {
    if (meetingItem) {
      setMeetingItem({
        ...meetingItem,
        recipients: meetingItem.recipients.filter((x) => x.email !== value)
      })
      setUpdateArg('Recipients')
    }
  }

  const onClicFinish = (data: {
    email: string
    last_name: string
    first_name: string
  }) => {
    const meetingService = new MeetingsDataService()
    if (meetingItem) {
      meetingService
        .updateMeeting(
          { ...meetingItem, recipients: [...meetingItem.recipients, data] },
          meetingItem.id
        )
        .then(() => {
          setLoading(false)
          getMeetingDetail(meetingItem.id)
          toast.success(`Recipients have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`Recipients haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const updateMeeting = (data: Meetings.Results | any, arg: string) => {
    const meetingService = new MeetingsDataService()
    if (meetingItem) {
      meetingService
        .updateMeeting(data, meetingItem.id)
        .then(() => {
          setLoading(false)
          getMeetingDetail(meetingItem.id)
          toast.success(`${arg} have been updated`, { theme: 'colored' })
        })
        .catch((error) => {
          toast.error(`${arg} haven't been updated correctly`, {
            theme: 'colored'
          })
          console.log(error)
        })
    }
  }

  const seeMore = (more: boolean) => {
    if (more) {
      setSeeMoreText('Hide')
      setSummaryArray(meetingItem ? meetingItem.summary.split('\n\n') : [])
    } else {
      setSeeMoreText('See more')
      setSummaryArray(
        meetingItem ? meetingItem.summary.split('\n\n').slice(0, 2) : []
      )
    }
  }

  const onClicFinishSummary = (data: { summary: string }) => {
    setSummaryEdit(false)
    setSeeMoreText('See more')
    if (meetingItem) {
      const dataRequest = {
        summary: data.summary
      }

      updateMeeting(dataRequest, 'Summary')
    }
  }

  // RENDERS
  const renderParticipants = (participants: string[] | undefined) => {
    return participants ? (
      <div style={{ padding: '10px' }}>
        {participants.map((participant, index) => {
          return (
            <p style={{ margin: '0' }} key={index}>
              {participant}
            </p>
          )
        })}
      </div>
    ) : (
      <div>NONE</div>
    )
  }

  const renderRecording = (
    urlRecording: string | undefined,
    urlFile: string | undefined
  ) => {
    return urlRecording ? (
      <div style={{ width: '1400px' }}>
        <TranscriptContainer
          open={open}
          urlFile={urlFile}
          urlVideo={urlRecording}
          transcriptJSON={meetingItem ? meetingItem.transcript_segments : []}
        />
      </div>
    ) : (
      <div>no disponible</div>
    )
  }

  const renderSendNotes = () => {
    return (
      <div style={{ padding: '20px' }}>
        <p>
          Are you sure you want to send this Meeting’s notes to all
          Participants?
        </p>
        <Form form={sendNotesForm} onFinish={onClicSendNotes}>
          <div style={{ display: 'flex' }}>
            <Form.Item name={'email'} valuePropName="checked">
              <Checkbox>Email</Checkbox>
            </Form.Item>
            <Form.Item name={'slack'} valuePropName="checked">
              <Checkbox>Slack</Checkbox>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              type="default"
              size="small"
              style={{ marginRight: '5px' }}
              onClick={() => setClosePopOver(!closePopOver)}
            >
              NO
            </Button>
            <Button htmlType="submit" type="primary" size="small">
              YES
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  const onClicChat = (idBot: string, meeting_bot_id: string) => {
    navigate(`/meetChat/${meeting_bot_id}/${idBot}`)
  }

  return loading ? (
    <Loading />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledH1V2 style={{ marginRight: '20px' }}>
          {meetingItem?.name}
        </StyledH1V2>
        <Pill
          getMeetingDetail={getMeetingDetail}
          type={undefined}
          name={`Send notes`}
          icon={send}
          onClick={() => console.log('clic')}
          closePopOver={closePopOver}
          content={renderSendNotes()}
        />
      </div>
      <div style={{ display: 'flex', margin: '15px 0' }}>
        <Pill
          type="primary"
          name={`${meetingItem?.participants.length.toString()} Participants`}
          icon={participants}
          onClick={() => console.log('clic')}
          content={renderParticipants(meetingItem?.participants)}
          getMeetingDetail={getMeetingDetail}
        />
        <Pill
          type="primary"
          name={moment(meetingItem?.start_time).format('MMM Do, YYYY hh:mm a')}
          icon={calendar}
          getMeetingDetail={getMeetingDetail}
        />
        <Pill
          type="primary"
          name={`${meetingItem?.duration} min`}
          icon={clock}
          getMeetingDetail={getMeetingDetail}
        />

        <Pill
          type="secondary"
          name={`${meetingItem?.recipients.length.toString()} Recipients`}
          icon={participants}
          onClick={() => console.log('clic')}
          getMeetingDetail={getMeetingDetail}
          content={
            <Recipients
              onClicFinish={onClicFinish}
              form={recipientsForm}
              onClicEdit={(value) => onClicEditRecipients(value)}
              onClicDelete={(value) => onClicDeleteRecipients(value)}
              recipients={meetingItem?.recipients}
            />
          }
        />

        {permissions && permissions.includes('custom_words') ? (
          <Pill
            getMeetingDetail={getMeetingDetail}
            type="secondary"
            name={`Custom Words`}
            icon={custom_words}
            content={<CustomWordsContainer />}
            onClick={() => console.log('clic')}
          />
        ) : (
          <StyledPillContent
            type="secondary"
            onClick={() => setShowModalCW(!showModalCW)}
          >
            <img
              src={custom_words}
              alt={'Custom Words'}
              width={'14px'}
              height={'14px'}
            />
            <p>Custom Words</p>
          </StyledPillContent>
        )}

        {permissions && permissions.includes('chat') ? (
          <Pill
            getMeetingDetail={getMeetingDetail}
            type="secondary"
            name={`Chat`}
            icon={chat}
            content={<ChatContainer />}
            onClick={() => {
              if (meetingItem) {
                onClicChat(meetingItem?.id, meetingItem?.meeting_bot_id)
              }
            }}
          />
        ) : (
          <StyledPillContent
            type="secondary"
            onClick={() => setShowModalChat(!showModalChat)}
          >
            <img src={chat} alt={'Chat'} width={'14px'} height={'14px'} />
            <p>Chat</p>
          </StyledPillContent>
        )}

        <RecordingButton
          onClick={() => {
            setOpen(true)
            setShowModal(true)
          }}
        >
          <PlayCircleOutlined />
          <p style={{ margin: '0 5px' }}>Recording & Transcript</p>
        </RecordingButton>

        {permissions && permissions.includes('video') ? (
          <Drawer title="Transcript" onClose={onClose} open={open} width={1500}>
            {renderRecording(
              meetingItem?.meeting_file,
              meetingItem?.transcript
            )}
          </Drawer>
        ) : (
          <UnlockFeature
            namePlan="Pro"
            featureName="Recording & Transcript"
            showModal={showModal}
            handleShowModal={() => {
              setShowModal(!showModal)
            }}
            handleUpgrade={() => console.log('upgrade')}
          />
        )}
      </div>
      {meetingItem &&
        meetingItem.report.map((item, index) => {
          return (
            item.component.component_type === 'GENERATION' &&  <Generation
            key={index}
            meetingItem={meetingItem}
            getMeetingDetail={getMeetingDetail}
          />
          )
        })}
      <div
        style={{ display: 'flex', margin: '15px 0', flexDirection: 'column' }}
      >
        {/* <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <StyledH2V2>Summary</StyledH2V2>
          <img
            src={edit}
            alt="edit"
            style={{ cursor: 'pointer', margin: '0 10px' }}
            onClick={() => {
              setSummaryEdit(!summaryEdit)
            }}
          />
        </div>
        <div hidden={summaryEdit}>
          {summaryArray.map((p, index) => {
            return (
              <p key={index} style={{ lineHeight: '24px' }}>
                {p}
              </p>
            )
          })}
          <p
            style={{ color: '#E0AA25', cursor: 'pointer' }}
            onClick={() => seeMore(seeMoreText === 'See more' ? true : false)}
          >
            <strong>{seeMoreText}</strong>
          </p>
        </div>
        <Form
          form={summaryForm}
          onFinish={onClicFinishSummary}
          hidden={!summaryEdit}
        >
          <Form.Item name={'summary'}>
            <Input.TextArea
              size="middle"
              style={{ height: '300px' }}
            ></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
        <br /> */}
        <TopicsContainer
          topics={meetingItem ? meetingItem.topics : []}
          getMeetingDetail={getMeetingDetail}
          url={meetingItem ? meetingItem?.meeting_file : ''}
        />
        <br />
        <TasksContainer
          tasks={meetingItem ? meetingItem.tasks : []}
          getMeetingDetail={getMeetingDetail}
          recipients={meetingItem ? meetingItem.recipients : []}
          url={meetingItem ? meetingItem?.meeting_file : ''}
        />
      </div>
      <UnlockFeature
        namePlan="Premium"
        featureName="Chat"
        showModal={showModalChat}
        handleShowModal={() => {
          setShowModalChat(!showModalChat)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
      <UnlockFeature
        namePlan="Pro"
        featureName="Custom Words"
        showModal={showModalCW}
        handleShowModal={() => {
          console.log('words')
          setShowModalCW(!showModalCW)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </div>
  )
}

export default MeetingDetialsContainer
